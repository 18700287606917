import * as React from "react";
const PrintingImage = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="39px"
    height="39px"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 39 39"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <style type="text/css" />
    </defs>
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <g id="_668293488">
        <path
          className="fil0"
          d="M-59 34l1 2 -1 1 -2 -1 2 -2zm9 -34l0 0c1,0 1,0 1,1 1,0 1,1 0,2 0,1 -1,1 -2,1l-3 3c1,0 4,-2 10,-1 2,0 4,1 6,2l2 1c0,0 1,-1 2,-1l2 2c0,1 0,1 0,1l0 0c0,1 0,1 0,1 -3,3 -3,3 -4,1 -1,0 -1,-1 -2,-1 -1,-1 0,-1 1,-2l-1 -1c-1,0 -1,0 -1,0 0,0 -1,0 -1,-1 -1,0 -3,-1 -4,-1 -1,0 -2,0 -3,0 -1,0 -2,0 -3,0 -2,0 -3,1 -5,2l-2 1c1,0 1,0 1,1 0,1 -1,3 -3,3 0,0 0,0 -1,0 0,0 0,0 -1,0 0,0 -1,2 -1,2 -2,4 -2,9 -1,13 1,1 1,3 2,4 0,0 0,0 0,0 1,0 1,-1 2,0l3 2c0,0 0,1 0,1l-2 2c-1,1 -1,1 -1,1l-1 0c0,0 0,-1 -1,-1 0,-1 -1,-2 -2,-2 0,-1 1,-1 1,-2 0,0 0,-1 -1,-2 0,0 0,0 0,0 -2,-4 -2,-7 -2,-11 0,-3 1,-4 1,-5l-3 4c0,0 0,1 -1,2 0,1 -1,1 -2,0 0,0 -1,-1 -1,-1l0 -1c1,0 1,-1 2,-1 1,0 0,0 1,0l6 -6c0,0 -1,-1 -1,-2 1,-2 2,-3 3,-3 1,0 2,0 2,1l6 -6c0,-1 0,-1 0,-2 0,-1 1,-1 2,-1zm-19 21c0,-1 1,-1 1,0 0,0 -1,0 -1,0zm10 -8c-3,0 -3,-4 -1,-4l1 0c1,0 2,1 2,2 0,1 -1,2 -2,2zm22 -2l2 -2 2 2 -2 2 -2 -2zm-17 -4l0 0zm3 -5c0,-1 2,-1 2,0 0,1 -2,1 -2,0z"
        />
        <path
          className="fil0"
          d="M-41 37l-2 -3 7 -6 3 1c0,1 -3,3 -3,3 -1,1 -1,1 -1,1 0,0 -1,0 -1,0l0 1c-1,1 -3,3 -3,3zm9 -8l0 0c0,0 0,0 0,1l-9 9c0,0 0,0 -1,0l0 0c0,0 -1,-3 -2,-3 0,0 0,-1 0,-1 0,0 0,0 -1,0 0,-1 -1,-1 -1,-1 -1,0 -2,-1 -2,-1 -1,0 -1,0 -2,-1 -1,0 -1,0 -1,-1l-4 -8c0,-1 -3,-7 -3,-7 0,-1 3,-3 3,-3 0,0 15,6 16,6 0,0 0,0 1,1l1 4c1,3 1,2 2,3 2,1 3,1 3,2zm-15 -3c0,-4 5,-4 4,0 0,1 -1,2 -3,2 -1,-1 -1,-1 -1,-2zm-8 -11l0 -1 15 6c1,0 1,0 1,1 0,1 2,5 2,6l-7 6 -6 -2c0,0 -1,-1 -1,-2l-5 -12c0,0 0,-1 0,-1l0 0 8 7c-2,3 0,6 3,6 1,0 2,0 2,-1 2,-2 2,-5 0,-6 -2,0 -3,0 -4,1l-8 -8z"
        />
      </g>
      <g id="_668291736">
        <path
          className="fil0"
          d="M36 17l1 0 0 18 -1 0 0 -18zm-34 0l1 0 0 18 -1 0 0 -18zm5 15l18 0c0,2 0,1 0,2l-15 0c-2,0 -2,0 -3,-1 0,-1 0,-1 0,-1zm11 -7c0,0 0,1 1,1l1 2c1,1 2,2 2,2l-9 0c1,0 1,0 1,-1l4 -4zm3 2c0,0 1,-1 1,-1 0,-1 1,-1 1,-1 0,0 0,0 0,-1l2 -2c0,0 0,0 0,0l1 1c1,0 1,0 1,1 1,0 2,2 2,2l0 7c0,1 -2,1 -3,1 0,-1 0,-1 0,-2 0,-2 0,-2 -2,-2 -1,-1 -2,-2 -3,-3zm-8 -4l-3 0 0 -3c0,0 0,0 1,1 0,1 0,1 2,1 1,0 1,1 0,1zm7 -3c-1,0 -2,0 -3,0 -2,-2 0,-5 1,-5l3 0c1,0 2,1 2,2l0 0c0,2 -2,3 -3,3zm15 -13l3 0 0 9 -3 0 0 -9zm-34 0l3 0 0 9 -3 0 0 -9zm30 9l0 -1c0,0 0,0 0,0l0 0c0,1 0,1 0,1zm-23 -1l0 0c0,0 0,0 0,0l0 1c0,0 0,0 0,-1zm7 2c0,2 1,4 3,4 1,1 2,1 3,0 3,-1 4,-3 3,-5 0,-1 -1,-1 -1,-1l6 0 0 9c0,0 0,0 0,-1l-3 -2c0,-1 -1,-2 -1,-1 -1,1 -4,6 -5,6 0,0 0,0 0,0l-1 -2c-1,0 -1,-1 -1,-1 -1,0 -2,1 -2,2 -1,1 -3,3 -4,5 0,0 0,0 -2,0l0 -6 3 0c2,0 3,-2 1,-3 -1,-1 -1,-1 -2,-1 0,0 -1,-1 -2,-1l0 -4 6 0c0,0 -1,1 -1,2zm-7 -4c0,-1 0,-1 1,-1l21 0c1,0 1,1 0,1l-21 0c-1,0 -1,0 -1,0zm24 -1l2 0 0 4 -2 0c1,-2 0,-1 0,-2 1,-1 0,-1 0,-2zm-26 1c0,0 1,1 1,1 -1,1 0,2 0,2l-2 0 0 -4 2 0 -1 1zm19 -6l9 0 0 4 -9 0 0 -4zm-20 0l18 0 0 4 -18 0 0 -4zm21 -3l8 0 0 1 -9 0c1,0 1,0 1,0 0,0 0,-1 0,-1zm-2 1l-22 0c-1,0 -1,0 -1,1 -1,0 -1,0 -1,1l0 9c0,0 0,1 1,1l0 17c0,2 -1,2 3,2 0,0 1,0 1,-1l0 -18 3 0 0 13c-2,0 -2,0 -2,2 0,1 1,3 3,3l19 0c1,0 3,-1 3,-2l0 -16 3 0 0 18c0,1 1,1 1,1 4,0 3,1 3,-4l0 -15c1,0 1,-1 1,-1l0 -9c0,-1 -1,-2 -1,-2l-3 0c0,-1 0,-2 -1,-2l-8 0c0,0 -1,0 -1,1l-1 1z"
        />
        <polygon className="fil0" points="26,9 33,9 33,8 26,8 " />
        <path
          className="fil0"
          d="M32 26c1,0 1,0 1,0l0 -3c0,-1 0,-1 -1,-1l0 4z"
        />
        <path className="fil0" d="M32 21c1,0 1,0 1,-1 0,0 0,0 -1,0l0 1z" />
        <path className="fil0" d="M13 8l0 1 1 0 0 -1 -1 0c0,0 0,0 0,0z" />
        <path className="fil0" d="M8 9l1 0 0 -1c0,0 0,0 0,0l-1 0 0 1z" />
        <path className="fil0" d="M10 8l0 1 2 0 0 -1 -1 0c-1,0 -1,0 -1,0z" />
      </g>
      <g id="_668323032">
        <path
          className="fil0"
          d="M416 29c0,0 2,-2 2,-2 1,0 1,0 1,1l2 4c1,0 1,1 2,2 1,1 2,2 1,3 0,0 -1,1 -1,1 -1,0 -2,-1 -2,-2 -1,-1 -1,-1 -1,-1l-3 -5c0,0 -1,0 -1,0 0,-1 0,-1 0,-1l0 0zm-8 -3c0,-2 1,-3 2,-3 1,-1 2,-2 3,-2l2 3c0,0 2,2 2,2l-3 3c-1,0 -2,0 -2,0 -2,0 -4,-2 -4,-3zm10 -15c0,2 2,5 2,7 2,2 3,4 6,6 0,0 1,1 1,1 0,0 -6,1 -7,1l-2 0c0,0 -1,-2 -1,-2l-3 -4c0,0 0,0 0,-1l4 -8c0,0 0,0 0,0zm1 -1c0,-1 0,-2 1,-2 1,-2 4,0 6,1l1 1c1,1 1,1 2,3 2,2 4,6 4,9 0,1 -1,2 -1,3 -2,0 -4,-1 -5,-2 0,0 -1,0 -1,0l-1 -1c-1,-2 -3,-5 -4,-7 0,0 0,0 0,0 -1,-2 -2,-4 -2,-5zm-12 15c0,1 0,2 1,3 0,1 0,1 0,1 1,1 3,2 4,2 1,-1 2,-1 3,-1l5 8c2,3 6,1 6,-2 0,-1 -1,-2 -1,-2 -1,-1 -4,-6 -5,-7 1,0 1,0 1,0 0,0 0,0 0,0l8 0c1,-1 3,-1 3,-1 2,0 2,-2 2,-4 0,-2 -1,-5 -1,-7 -1,-1 -2,-2 -3,-4 -2,-2 -7,-7 -11,-5 0,0 0,0 -1,1l-5 11c-1,1 -1,1 -1,2 -1,0 -1,0 -2,0 -1,2 -2,2 -3,4 0,0 0,1 0,1z"
        />
        <path
          className="fil0"
          d="M422 14c0,1 1,1 1,1 0,0 1,-1 2,-1 1,0 2,1 2,3 0,2 -1,2 -1,3 0,1 2,0 2,-1 2,-3 0,-6 -3,-6 -1,0 -1,0 -2,0 0,0 -1,1 -1,1z"
        />
        <path
          className="fil0"
          d="M435 10c0,0 0,1 1,1 0,0 3,-2 3,-2 1,-1 1,-1 2,-1 0,-1 1,-1 0,-2 0,-1 -1,0 -1,0l-4 3c-1,0 -1,1 -1,1z"
        />
        <path
          className="fil0"
          d="M436 15c0,1 1,1 1,1 1,0 6,1 6,1 1,0 1,-1 0,-2l-6 -1c-1,0 -1,1 -1,1z"
        />
        <path
          className="fil0"
          d="M432 7c1,0 1,0 1,-1 0,-1 0,-5 0,-5 0,-1 -1,-1 -1,0 0,0 -1,6 -1,6 0,0 1,0 1,0z"
        />
      </g>
    </g>
  </svg>
);
export default PrintingImage;
