import * as React from "react";
const AdvertisingImage = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="39px"
    height="39px"
    style={{
      shapeRendering: "geometricPrecision",
      textRendering: "geometricPrecision",
      imageRendering: "optimizeQuality",
      fillRule: "evenodd",
      clipRule: "evenodd",
    }}
    viewBox="0 0 39 39"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <style type="text/css" />
    </defs>
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <g id="_625707672">
        <path
          className="fil0"
          d="M-182 34l2 2 -2 1 -2 -1 2 -2zm9 -34l1 0c0,0 0,0 1,1 0,0 0,1 0,2 -1,1 -2,1 -3,1l-3 3c1,0 4,-2 10,-1 2,0 4,1 6,2l2 1c0,0 1,-1 2,-1l2 2c0,1 1,1 1,1l0 0c0,1 -1,1 -1,1 -3,3 -2,3 -4,1 -1,0 -1,-1 -2,-1 0,-1 1,-1 1,-2l-1 -1c0,0 -1,0 -1,0 0,0 0,0 -1,-1 -1,0 -2,-1 -4,-1 -1,0 -2,0 -3,0 -1,0 -2,0 -3,0 -1,0 -3,1 -4,2l-2 1c0,0 0,0 0,1 0,1 -1,3 -3,3 0,0 0,0 -1,0 0,0 0,0 -1,0 0,0 -1,2 -1,2 -2,4 -2,9 -1,13 1,1 2,3 2,4 0,0 1,0 1,0 0,0 1,-1 1,0l3 2c0,0 0,1 0,1l-2 2c-1,1 -1,1 -1,1l0 0c-1,0 -1,-1 -2,-1 0,-1 -1,-2 -1,-2 -1,-1 0,-1 1,-2 0,0 -1,-1 -1,-2 -1,0 -1,0 -1,0 -2,-4 -2,-7 -2,-11 1,-3 1,-4 2,-5l-4 4c0,0 1,1 0,2 -1,1 -2,1 -3,0 0,0 0,-1 0,-1l0 -1c0,0 0,-1 1,-1 1,0 1,0 1,0l6 -6c0,0 0,-1 0,-2 0,-2 1,-3 2,-3 2,0 2,0 3,1l5 -6c0,-1 0,-1 0,-2 1,-1 1,-1 2,-1zm-19 21c0,-1 1,-1 1,0 0,0 -1,0 -1,0zm10 -8c-3,0 -2,-4 0,-4l0 0c1,0 2,1 2,2 0,1 -1,2 -2,2zm23 -2l1 -2 2 2 -2 2 -1 -2zm-18 -4l0 0zm4 -5c0,-1 1,-1 1,0 0,1 -1,1 -1,0z"
        />
        <path
          className="fil0"
          d="M-164 37l-2 -3 7 -6 3 1c0,1 -3,3 -3,3 0,1 -1,1 -1,1 0,0 0,0 0,0l-1 1c-1,1 -3,3 -3,3zm10 -8l0 0c0,0 -1,0 -1,1l-9 9c0,0 0,0 -1,0l0 0c0,0 -1,-3 -1,-3 -1,0 -1,-1 -1,-1 0,0 0,0 0,0 -1,-1 -2,-1 -2,-1 -1,0 -1,-1 -2,-1 -1,0 -1,0 -2,-1 -1,0 -1,0 -1,-1l-4 -8c0,-1 -2,-7 -2,-7 0,-1 2,-3 2,-3 1,0 15,6 16,6 0,0 1,0 1,1l1 4c1,3 1,2 3,3 1,1 3,1 3,2zm-16 -3c0,-4 5,-4 4,0 0,1 -1,2 -3,2 -1,-1 -1,-1 -1,-2zm-8 -11l1 -1 14 6c1,0 1,0 1,1 0,1 2,5 2,6l-7 6 -6 -2c0,0 0,-1 -1,-2l-4 -12c-1,0 -1,-1 -1,-1l0 0 8 7c-2,3 1,6 3,6 1,0 2,0 3,-1 1,-2 1,-5 -1,-6 -1,0 -3,0 -4,1l-8 -8z"
        />
      </g>
      <g id="_625705920">
        <path
          className="fil0"
          d="M-87 17l1 0 0 18 -1 0 0 -18zm-34 0l1 0 0 18 -1 0 0 -18zm5 15l18 0c0,2 0,1 0,2l-15 0c-1,0 -2,0 -2,-1 -1,-1 -1,-1 -1,-1zm11 -7c0,0 1,1 1,1l2 2c0,1 1,2 1,2l-8 0c0,0 0,0 0,-1l4 -4zm3 2c1,0 1,-1 1,-1 1,-1 1,-1 1,-1 0,0 0,0 0,-1l2 -2c0,0 0,0 1,0l1 1c0,0 0,0 1,1 0,0 1,2 1,2l0 7c0,1 -2,1 -2,1 -1,-1 -1,-1 -1,-2 0,-2 0,-2 -2,-2 -1,-1 -2,-2 -3,-3zm-8 -4l-3 0 0 -3c0,0 1,0 1,1 1,1 0,1 2,1 1,0 1,1 0,1zm7 -3c-1,0 -2,0 -3,0 -2,-2 0,-5 1,-5l4 0c0,0 1,1 1,2l0 0c0,2 -2,3 -3,3zm15 -13l3 0 0 9 -3 0 0 -9zm-33 0l2 0 0 9 -2 0 0 -9zm29 9l0 -1c0,0 0,0 0,0l0 0c0,1 0,1 0,1zm-23 -1l0 0c0,0 0,0 1,0l0 1c-1,0 -1,0 -1,-1zm7 2c0,2 1,4 3,4 1,1 2,1 4,0 2,-1 3,-3 2,-5 0,-1 0,-1 0,-1l5 0 0 9c0,0 0,0 0,-1l-2 -2c-1,-1 -1,-2 -2,-1 -1,1 -4,6 -5,6 0,0 0,0 0,0l-1 -2c0,0 -1,-1 -1,-1 -1,0 -1,1 -2,2 -1,1 -3,3 -4,5 0,0 0,0 -2,0l0 -6 3 0c2,0 3,-2 1,-3 0,-1 -1,-1 -2,-1 0,0 -1,-1 -2,-1l0 -4 6 0c0,0 -1,1 -1,2zm-7 -4c0,-1 0,-1 1,-1l22 0c1,0 1,1 0,1l-22 0c-1,0 -1,0 -1,0zm25 -1l1 0 0 4 -1 0c0,-2 0,-1 -1,-2 1,-1 1,-1 1,-2zm-26 1c0,0 0,1 0,1 -1,1 0,2 0,2l-2 0 0 -4 2 0 0 1zm18 -6l9 0 0 4 -9 0 0 -4zm-20 0l19 0 0 4 -19 0 0 -4zm22 -3l7 0 0 1 -8 0c0,0 0,0 0,0 0,0 0,-1 1,-1zm-3 1l-22 0c0,0 -1,0 -1,1 0,0 -1,0 -1,1l0 9c0,0 0,1 1,1l0 17c0,2 0,2 3,2 0,0 1,0 1,-1l0 -18 4 0 0 13c-3,0 -3,0 -3,2 0,1 1,3 3,3l19 0c2,0 3,-1 3,-2l0 -16 4 0 0 18c0,1 0,1 0,1 4,0 4,1 4,-4l0 -15c0,0 0,-1 0,-1l0 -9c0,-1 -1,-2 -1,-2l-3 0c0,-1 1,-2 0,-2l-9 0c0,0 -1,0 -1,1l-1 1z"
        />
        <polygon className="fil0" points="-97,9 -90,9 -90,8 -97,8 " />
        <path
          className="fil0"
          d="M-91 26c1,0 1,0 1,0l0 -3c0,-1 0,-1 -1,-1l0 4z"
        />
        <path className="fil0" d="M-91 21c2,0 1,0 1,-1 0,0 0,0 -1,0l0 1z" />
        <path className="fil0" d="M-110 8l0 1 2 0 0 -1 -2 0c0,0 0,0 0,0z" />
        <path className="fil0" d="M-115 9l1 0 0 -1c0,0 0,0 0,0l-1 0 0 1z" />
        <path className="fil0" d="M-112 8l0 1 1 0 0 -1 -1 0c0,0 0,0 0,0z" />
      </g>
      <g id="_625704864">
        <path
          className="fil0"
          d="M10 29c0,0 2,-2 2,-2 1,0 1,0 1,1l3 3c0,1 1,2 1,3 1,1 2,2 1,3 0,0 -1,0 -1,0 -1,0 -2,-1 -2,-1 0,-1 -1,-1 -1,-1l-3 -5c0,0 0,0 -1,-1 0,0 0,0 0,0l0 0zm-7 -4c0,-1 0,-2 1,-3 1,0 2,-1 3,-1l2 2c0,1 2,3 2,3l-3 2c-1,1 -1,1 -2,1 -2,0 -3,-2 -3,-4zm9 -14c0,2 2,5 3,6 1,3 3,5 5,7 0,0 1,1 1,1 0,0 -5,0 -6,0l-3 1c0,-1 -1,-2 -1,-2l-3 -4c0,-1 0,-1 0,-1l4 -8c0,0 0,0 0,0zm2 -1c0,-1 -1,-2 0,-3 1,-2 5,1 6,2l1 1c1,1 1,1 2,2 2,3 4,7 4,10 0,1 0,2 -1,2 -2,1 -4,0 -5,-1 0,0 0,-1 0,-1l-1 -1c-2,-1 -4,-4 -5,-6 0,0 0,0 0,-1 -1,-1 -1,-3 -1,-4zm-13 15c0,1 0,2 1,3 0,0 0,0 1,1 0,1 2,1 3,1 2,0 2,0 3,0l5 7c2,4 6,2 6,-1 0,-1 -1,-2 -1,-3 -1,0 -4,-5 -4,-6 0,0 0,0 0,0 0,0 0,0 1,0l7 -1c1,0 3,0 3,0 2,-1 3,-2 3,-4 0,-2 -1,-5 -2,-7 -1,-1 -2,-2 -3,-4 -1,-2 -7,-8 -10,-5 -1,0 -1,0 -1,1l-6 11c0,1 0,1 -1,1 -1,1 -1,1 -1,1 -2,1 -3,2 -4,3 0,1 0,2 0,2z"
        />
        <path
          className="fil0"
          d="M16 14c0,0 1,1 1,1 0,0 1,-1 2,-1 1,0 2,1 2,2 0,3 -1,2 -1,4 0,1 2,0 2,-1 2,-3 0,-6 -3,-6 -1,0 -1,-1 -2,0 0,0 -1,0 -1,1z"
        />
        <path
          className="fil0"
          d="M29 10c0,0 0,1 1,1 0,0 3,-2 3,-2 1,-1 1,-1 2,-1 0,-1 1,-1 1,-2 -1,-1 -2,0 -2,0l-4 3c0,0 -1,0 -1,1z"
        />
        <path
          className="fil0"
          d="M30 15c0,1 1,1 2,1 0,0 5,1 5,1 1,0 1,-2 0,-2l-6 -1c0,0 -1,0 -1,1z"
        />
        <path
          className="fil0"
          d="M26 7c1,0 1,0 1,-1 0,-1 1,-5 0,-6 0,0 -1,0 -1,0 0,1 -1,6 -1,7 0,0 1,0 1,0z"
        />
      </g>
    </g>
  </svg>
);
export default AdvertisingImage;
