import React from "react";
import { slugify } from "../../../utils";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const BlogListTwo = ({ data, StyleVar }) => {
  // const cate = data.categories.map((value, i) => {
  //     return (
  //         <Link to={process.env.REACT_APP_BASE_URL + `/category/${slugify(value)}`} key={i}>{value}{i !== data.categories.length - 1 && ","}</Link>
  //     )
  // });

  const dateStr = data.updatedAt;

  const date = new Date(dateStr);

  const options = { year: "numeric", month: "short", day: "numeric" };

  const formattedDate = date.toLocaleDateString("en-US", options);

  return (
    <div className={`rn-card ${StyleVar}`}>
      <div className="inner">
        <div className="thumbnail">
          <Link
            to={`/blog-details/${data._id}`}
            className="image"
          >
            <img
              src={`${process.env.REACT_APP_PUBLIC_URL}/${data.blogImage}`}
              alt="Blog Image"
            />
          </Link>
        </div>
        <div className="content">
          <h4 className="title">
            <Link
              to={`/blog-details/${data._id}`}
            >
              {data.blogName}
            </Link>
          </h4>
          <div className="blog-details-content-description">
          <div className="rn-meta-list">
            <span>{formattedDate}</span><span style={{marginLeft:10}}>- {data?.author !== "undefined" ? data?.author : ""}</span>
          </div>
          <div className="description-container">
          <div className="descriptiion" dangerouslySetInnerHTML={{__html:data.description}}></div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};
BlogListTwo.propTypes = {
  data: PropTypes.object,
};
export default BlogListTwo;
